import React, { Fragment } from 'react'
import Script from 'next/script'
import { SmeApiTrackingEndpoint } from '@/env'

const CjAffiliate = () => {
  return (
    <Fragment>
      <Script id="cj-tracking" strategy="afterInteractive">
        {`
        (async () => {
          const queryString = window.location.search
          const urlParams = new URLSearchParams(queryString)
          const cj_event = urlParams.get('cjevent')
          if(cj_event){
            const cj_aid = urlParams.get('AID') ?? ''
            const cj_pid = urlParams.get('PID') ?? ''

            let cookie_name = 'cje';
            let domain = '.'+ window.location.hostname.split('.').slice(-2).join(".")
            // let domain = 'localhost';
            let expires = "expires="+ new Date(Date.now() + (86400 * 395 * 1000)).toUTCString();
            // document.cookie = 'cje=' + cj_event + '; ' + expires +' ; path=/; domain=' +domain+ '; secure;'

            // Extra CJ Data
            // document.cookie = 'cj_aid=' + cj_aid + '; ' + expires +' ; path=/; domain=' +domain+ '; secure;'
            // document.cookie = 'cj_pid=' + cj_pid + '; ' + expires +' ; path=/; domain=' +domain+ '; secure;'
            
            // expire tapfillate
            document.cookie = "tap_ref=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + domain + ";";
            document.cookie = "tap_vid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + domain + ";";

            let cj_ref_data = cj_event + '-' + cj_aid + '-' + cj_pid;

            const stripe_urls = document.getElementsByClassName('stripe_url')
            Array.from(stripe_urls).forEach(element => {
              const currentLink = element.href
              const elementParams = new URL(currentLink);
              const ref_id = elementParams.searchParams.get('client_reference_id')

              if (!ref_id){
                elementParams.searchParams.append('client_reference_id', cj_ref_data)
                element.href = elementParams.href
              }else{
                const newLink = currentLink.replace(ref_id, cj_ref_data)
                element.href = newLink
              }
            });


            const sme_urls = document.getElementsByClassName('sme_url')
            Array.from(sme_urls).forEach(element => {
              const currentLink = element.href
              const elementParams = new URL(currentLink);
              const cj_ref_val = elementParams.searchParams.get('cjevent');
              const cj_aid_val = elementParams.searchParams.get('AID');
              const cj_pid_val = elementParams.searchParams.get('PID');

              if(elementParams.searchParams.get('ref')){
                elementParams.searchParams.delete('ref')
              }
              
              if (!cj_ref_val){
                elementParams.searchParams.append('cjevent', cj_event)
                elementParams.searchParams.append('AID', cj_aid)
                elementParams.searchParams.append('PID', cj_pid)
                element.href = elementParams.href
              }else{
                const replace_cj = currentLink.replace(cj_ref_val, cj_event)
                const replace_aid = replace_cj.replace(cj_aid_val, cj_aid)
                const final_url = replace_aid.replace(cj_pid_val, cj_pid)
                element.href = final_url
              }
            });

            const ref_urls = document.getElementsByClassName('ref_url')
            Array.from(ref_urls).forEach(element => {
              const currentLink = element.href
              const elementParams = new URL(currentLink);
              const cj_ref_val = elementParams.searchParams.get('cjevent');

              if(elementParams.searchParams.get('ref')){
                elementParams.searchParams.delete('ref')
              }
              
              if (!cj_ref_val){
                elementParams.searchParams.append('cjevent', cj_event)
                element.href = elementParams.href
              }else{
                const newLink = currentLink.replace(cj_ref_val, cj_event)
                element.href = newLink
              }
            });
            
            const formData = new FormData();
            formData.append('referral_type', 'cj');
            formData.append('referral_code', 'cj');
            formData.append('landing_page', window.location.href);
            if (urlParams.get('utm_source')) {
                formData.append('source', urlParams.get('utm_source'));
            }

            formData.append('tracking_id', cj_ref_data);
            
            await fetch('${SmeApiTrackingEndpoint}', {
              method: 'POST',
              body: formData
            }).then(() => {}).catch((err) => {
                console.log('conversion tracking cj error', err)
            });
          }
        })();
      `}
      </Script>
    </Fragment>
  )
}

export default CjAffiliate
