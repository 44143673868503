import React, { Fragment } from 'react'
import Script from 'next/script'
import { TapFiliateAccountID, SmeApiTrackingEndpoint } from '@/env'

const TapFiliate = () => {
  return (
    <Fragment>
      <Script src="https://script.tapfiliate.com/tapfiliate.js" strategy="afterInteractive" />
      <Script id="tapfiliate-tracking" strategy="afterInteractive">
        {`(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
  (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

  tap('create', '${TapFiliateAccountID}');
  tap('detect', {always_callback: true}, async (err, result) => {
    if (!err) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const tap_ref = urlParams.get('ref')
        const formData = new FormData()
        formData.append('referral_type', 'tapfiliate')
        formData.append('referral_code', urlParams.get('ref'))
        formData.append('landing_page', window.location.href)
        if (urlParams.get('utm_source')) {
            formData.append('source', urlParams.get('utm_source'))
        }
        formData.append('tracking_id', result.vid)
        
        const refD = new Date();
        refD.setTime(refD.getTime() + (365*24*60*60*1000));
        let expires = refD.toUTCString();
        let domain = '.'+ window.location.hostname.split('.').slice(-2).join(".")
        // let domain = 'localhost';
        document.cookie = 'tap_ref='+ tap_ref + ';' + expires +'; path=/';
        
        // expire cje
        document.cookie = "cje=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + domain + ";"
        document.cookie = "cj_aid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + domain + ";"
        document.cookie = "cj_pid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + domain + ";"
        
        const stripe_urls = document.getElementsByClassName('stripe_url')
        Array.from(stripe_urls).forEach(element => {
          const currentLink = element.href
          const elementParams = new URL(currentLink);
          const ref_id = elementParams.searchParams.get('client_reference_id')

          if (!ref_id){
            elementParams.searchParams.append('client_reference_id', result.vid)
            element.href = elementParams.href
          }else{
            const newLink = currentLink.replace(ref_id, result.vid)
            element.href = newLink
          }
        });

        const ref_urls = document.getElementsByClassName('ref_url')
        Array.from(ref_urls).forEach(element => {
          const currentLink = element.href
          const elementParams = new URL(currentLink);
          const ref_val = elementParams.searchParams.get('ref')

          if(elementParams.searchParams.get('cjevent')){
            elementParams.searchParams.delete('cjevent')
            elementParams.searchParams.delete('AID')
            elementParams.searchParams.delete('PID')
          }

          if (!ref_val){
            elementParams.searchParams.append('ref', tap_ref)
            element.href = elementParams.href
          }else{
            const newLink = currentLink.replace(ref_val, tap_ref)
            element.href = newLink
          }
        });

        const sme_urls = document.getElementsByClassName('sme_url')
        Array.from(sme_urls).forEach(element => {
          const currentLink = element.href
          const elementParams = new URL(currentLink);
          const ref_val = elementParams.searchParams.get('ref')

          if(elementParams.searchParams.get('cjevent')){
            elementParams.searchParams.delete('cjevent')
            elementParams.searchParams.delete('AID')
            elementParams.searchParams.delete('PID')
          }

          if (!ref_val){
            elementParams.searchParams.append('ref', tap_ref)
            element.href = elementParams.href
          }else{
            const newLink = currentLink.replace(ref_val, tap_ref)
            element.href = newLink
          }
        });

        await fetch('${SmeApiTrackingEndpoint}', {
            method: 'POST',
            body: formData
        }).then(() => {}).catch((err) => {
            console.log('conversion tracking tapfiliate error', err);
        });
    }
  });`}
      </Script>
    </Fragment>
  )
}

export default TapFiliate