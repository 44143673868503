import '@/styles/globals.css'
// import '@/styles/globals.local.css'
import type { AppProps } from 'next/app'
import { GraphQLClient } from 'graphql-request'
import { Fragment } from 'react'
import { NEXT_PUBLIC_GRAPHQL_URI } from '@/env'
import 'react-multi-carousel/lib/styles.css'
import GoogleTagManager from '@/components/core/GoogleTagManager'
import TapFiliate from 'components/core/TapFiliate'
import CjAffiliate from '@/components/core/CjAffiliate'
import ReferenceChecker from '@/components/core/ReferenceChecker'
// import Script from 'next/script'

export const ApiClient = new GraphQLClient(NEXT_PUBLIC_GRAPHQL_URI)

export default function App({ Component, pageProps }: AppProps) {
  return (
    <Fragment>
      {/* <Script strategy='beforeInteractive'>
        {`
          delete window.document.referrer;
          window.document.__defineGetter__('referrer', function () {
              return "https://packetpuppy.com/";
          });
        `}
      </Script> */}
      <TapFiliate />
      <GoogleTagManager />
      <CjAffiliate />
      <Component {...pageProps} />
      <ReferenceChecker />
    </Fragment>
  )
}
