import React from 'react'
import Script from 'next/script'

type Props = {}

const ReferenceChecker = (props: Props) => {
  return (
    <Script strategy='afterInteractive'>
      {`
        (async () => {
          const extractSubdomainAndDomainFromUrl = (url) => {
            let domain, subdomains, full_domain
            let parts = url.split('/')
            if (parts[0].indexOf(':') === -1) {
              parts[0] = 'https:' + parts[0]
            }
            let parser = new URL(parts[0])
            full_domain = parser.hostname
            subdomains = full_domain.split('.')
            domain = subdomains.slice(subdomains.length - 2, subdomains.length).join('.')
            if (subdomains.length > 2) {
              subdomains = subdomains.slice(0, subdomains.length - 2).join('.')
            } else {
              subdomains = null
            }
            return {
              domain: domain,
              subdomains: subdomains,
            }
          }
          const docRef = document.referrer;
          if(!docRef || docRef.includes(window.location.hostname)){
            return;
          }
          const vpn_portal_url = document.getElementsByClassName('vpn_portal_url')
          if(vpn_portal_url && vpn_portal_url.length){
            let url = '/api/checkref?ref='+ docRef
            await fetch(url)
            .then(response => response.json())
            .then(data => {
              if(!data.domain){
                return;
              }
              let ref_matched_val = data.domain;
              Array.from(vpn_portal_url).forEach((element) => {
                let element_href = element.href
                const { domain: element_href_domain } = extractSubdomainAndDomainFromUrl(new URL(element_href).hostname)
                if (element_href_domain && element_href_domain !== ref_matched_val){
                  const update_url = element_href.replaceAll(element_href_domain, ref_matched_val)
                  element.href = update_url
                }
              });
            });
          }
        })();
      `}
    </Script>
  )
}

export default ReferenceChecker